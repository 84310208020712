import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import pic12 from '../assets/images/pic12.jpg';

const About = props => (
  <Layout>
    <Helmet>
      <title>About - Horovatin Consulting Ltd.</title>
      <meta name="description" content="About Horovatin Consulting Ltd." />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>About Us</h1>
          </header>
          <span className="image main">
            <img
              src={pic12}
              alt="View from the stands of an Okotoks Dawgs baseball game"
            />
          </span>
          <p>
            Hi! We are Adele and Ken Horovatin, owners of Horovatin Consulting
            Ltd., an Alberta-based corporation.
          </p>
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h3>Adele Horovatin</h3>
                <p>Compensation and Human Resources Specialist</p>
              </header>
              <p>
                Adele has a passion for compensation and has always been
                intrigued with “why someone makes what they make”. A
                self-professed “Job Whisperer”, Adele enjoys listening to people
                talk about what they do and seeing the passion they have for
                their work.
              </p>
              <p>
                Certified in both HR and Compensation, (CPHR and CCP) Adele has
                enjoyed a career with a variety of industries from Post-Secondary 
                Education, Health Care, Finance, Insurance, and Telecommunications,
                to Oil and Gas, each having their own challenges and
                opportunities…always looking and learning, working to make a
                difference.
              </p>
              <ul className="icons">
                <li>
                  <a
                    href="https://www.linkedin.com/in/adelehorovatin/"
                    className="icon alt fa-linkedin"
                  >
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6">
              <header className="major">
                <h3>Ken Horovatin</h3>
                <p>Quality Assurance Specialist, Data Science Enthusiast, 
                  and Computer Geek</p>
              </header>
              <p>
                Ken has been messing around with computers for so long he used
                to call William Gates at MITS, (later Microsoft) for technical
                support. His computer software career has evolved from “play
                with it until it breaks” to “let’s design this better so it
                doesn’t break”.
              </p>
              <p>
                Over his career Ken has worked for some of the big software
                names, including Microsoft, Autodesk, and PageAhead. In
                addition, he has had many opportunities to provide professional
                software quality support to more local names like Cenovus, Shaw,
                AESO, and Pason Systems Corp.
              </p>
              <ul className="icons">
                <li>
                  <a
                    href="https://twitter.com/khorovatin"
                    className="icon alt fa-twitter"
                  >
                    <span className="label">Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/ken.horovatin"
                    className="icon alt fa-facebook"
                  >
                    <span className="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/khorovatin/"
                    className="icon alt fa-instagram"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/khorovatin"
                    className="icon alt fa-github"
                  >
                    <span className="label">GitHub</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/kenhorovatin/"
                    className="icon alt fa-linkedin"
                  >
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default About;
